var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    attrs: {
      "id": "topnav-login"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "border-bottom-nav",
    attrs: {
      "id": "navigation"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    directives: [{
      name: "scroll-spy-active",
      rawName: "v-scroll-spy-active",
      value: {
        selector: 'li.has-submenu'
      },
      expression: "{ selector: 'li.has-submenu' }"
    }],
    staticClass: "navigation-menu mb-0"
  }, [_c('li', {
    staticClass: "has-submenu"
  }, [_c('a', {
    attrs: {
      "href": "https://awarisgroup.com/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left mr-1"
  }), _vm._v(_vm._s(_vm.$t("navbar.homepage")))])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])])])])])]), _c('div', {
    staticClass: "menu-extras"
  }, [_c('div', {
    staticClass: "menu-item"
  }, [_c('a', {
    staticClass: "navbar-toggle",
    class: {
      open: _vm.isCondensed === true
    },
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_vm._m(0)])])])]), _c('section', {
    staticClass: "bg-home d-flex align-items-center"
  }, [_c('div', {
    staticClass: "bg-overlay bg-overlay-white bg-overlay-img"
  }), _c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "100px"
    }
  }, [_c('div', {
    staticClass: "row justify-content-center",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c('div', {
    staticClass: "card login-page bg-white shadow rounded-login border-0"
  }, [_c('div', {
    staticClass: "card-body-login"
  }, [_vm._m(1), _c('form', {
    staticClass: "login-form mt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('mail-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('email'),
      "name": "email"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "col-lg-12 mb-0"
  }, [_c('b-button', {
    staticClass: "btn btn-primary btn-block spinner spinner-light spinner-right",
    on: {
      "click": _vm.resetPassword
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.reset")) + " ")])], 1), _c('div', {
    staticClass: "col-12 text-center"
  }, [_c('p', {
    staticClass: "mb-0 mt-3"
  }, [_c('router-link', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v(_vm._s(_vm.$t("log-in")))])], 1)])])])])])])])]), _c('div', [_c('footer', {
    staticClass: "footer-login footer-bar"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 mb-3"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _c('br'), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lines"
  }, [_c('span'), _c('span'), _c('span')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-img"
  }, [_c('img', {
    staticClass: "img-center",
    attrs: {
      "src": "/images/logo/AWARIS_Logo_Col.png",
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }